import React, { useState, useEffect } from 'react';
import { DeleteUser, ReturnUsers, listenToCollection } from '../../../services/firestore';
import { auth, db, authForCreatingUsers } from '../../../services/firebase';
import { Container, Row, Col, Input, Label, Button } from 'reactstrap';
import styled from 'styled-components';
import firebase from "firebase/app";
import emailjs, { init } from 'emailjs-com';


const AdminRegionalEnrolParticipant = (props) => {
    init("user_8Qt3ZJv9dlnGhiJefkhBh");
    
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [isStaff, setIsStaff] = useState(false);
    const [message, setMessage] = useState("");

    const doSubmit = () => {
        let pass = Math.random().toString(36).slice(-8);
        const data  = {
            username: username,
            password: pass,
            email: email,
            isStaff: false,
            region: props.user.region,
            firstName: username,
        }

        if (username === "" || email === "") {
            setMessage("Please fill in all fields.");
            return;
        }
        
        authForCreatingUsers.createUserWithEmailAndPassword(data.email, data.password)
        .then((userCredential) => {
            let user = userCredential.user;
            user.updateProfile({
                displayName: data.username,
                //TODO may need to add more information to the profile here
            }).then(function(){
                db.collection("users").doc(user.uid).set({
                    firstName: data.username,
                    username: data.username,
                    uid: user.uid,
                    email: data.email,
                    accountCreatedTimestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                    isAdmin: false,
                    isAccountAccepted: false,
                    emailVerified: false,
                    isArchived: false,
                    region: data.region,
                    firstTimeLogin: true,
                }, db.SetOptions)
                .then(function() {
                    emailjs.send("service_3nzmm0t", "template_kzonssl", {
                        name: data.username,
                        email: data.email,
                        password: data.password,
                    })
                    }).then(function() {
                        console.log("Email sent successfully");
                        setMessage(`User: ${username}, with email: ${email} has been successfully created.`);
                        setUsername("");
                        setEmail("");
                        setIsStaff(false);
                        authForCreatingUsers.signOut();
                    })
                .catch(function(error) {
                    console.error("Error writing document: ", error);
                    setMessage(`Error creating user. You tried to create user with a first name: ${username}, and email: ${email}. If those look correct, please reach out to Eric.Blanchard@Deleptual.ca for support.`);
                });
            })
        })
        .catch((error) => {
            var errorMessage = error.message;
            console.log(errorMessage);
            setMessage("There was an error: " + errorMessage + "\nIf this is unexpected, please reach out to a site admin.");
        });  
        
    };

    return ( 
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                <Row>
                    <Col>
                        <h1>Enroll New User</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>First Name</StyledP>
                        <StyledInput value={username} onChange={(e) => { setUsername(e.target.value); setMessage("");}}></StyledInput>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>Email Address</StyledP>
                        <StyledInput value={email} onChange={(e) => { setEmail(e.target.value); setMessage("");}}></StyledInput>
                    </Col>
                </Row>
                <Row style={{ display:'flex', flexDirection:'row'}}>
                    <Col xs='1'>
                        <StyledP>Region:</StyledP>
                    </Col>
                    <Col>
                        <StyledP>{props.user.region}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Button onClick={() => doSubmit()}>Send Invite</Button>
                    </Col>
                    <Col>
                        <p>{message}</p>
                    </Col>
                </Row>
            </StyledContainer>
        </React.Fragment>
    );
}
 
export default AdminRegionalEnrolParticipant;

const StyledP = styled.p`
    margin: 0px;
`;

const StyledInput = styled(Input)`
max-width: 250px;
appearance: menulist;
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1200px;
    margin-top: 15px;
    margin-bottom: 80px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;